import {createRouter, createWebHistory} from "vue-router";
import {hasAuthToken} from "@/lib/utils.ts";

const router = createRouter({
  history: createWebHistory(),
  routes: [
      {
          name: "/orders/:tab",
          path: "/orders/:tab",
          component: () => import("@/routes/orders/[tab].vue"),

      },
      {
          name: "/inventory/:tab",
          path: "/inventory/:tab",
          component: () => import("@/routes/inventory/[tab].vue"),
      },
      {
          name: "/sales/:tab",
          path: "/sales/:tab",
          component: () => import("@/routes/sales/[tab].vue"),
      },
      {
          name: "/users",
          path: "/users",
          component: () => import("@/routes/users/index.vue"),
      },
      {
          name: "/settings",
          path: "/settings",
          component: () => import("@/routes/settings/index.vue"),
      },
      {
          name: "/bulk",
          path: "/bulk",
          component: () => import("@/routes/bulk/index.vue"),
      },
      {
          name: "/repricing",
          path: "/repricing",
          component: () => import("@/routes/repricing/index.vue"),
      },
      {
          name: "/order/:id",
          path: "/order/:id",
          component: () => import("@/routes/order/[id].vue"),
      },
      {
          name: "login",
          path: "/login",
          component: () => import("@/routes/login/index.vue"),
      }
  ],
})

router.beforeEach((to, _, next) => {
  if (to.name !== 'login' && !hasAuthToken()) next({ name: 'login' })
  else next()
})

export default router