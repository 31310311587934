<script setup lang="ts">
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Button } from "@/components/ui/button";
import { defineProps, defineEmits } from "vue";
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { useMutation } from '@tanstack/vue-query'
import axios from 'axios'
import {Input} from "@/components/ui/input";
import {useToast} from "@/components/ui/toast";
import { Loader2 } from 'lucide-vue-next'



defineProps(["open"]);

const emit = defineEmits(["close"]);
const { toast } = useToast()
const formSchema = toTypedSchema(z.object({
  file: z
      .any()
      .refine((file) => file instanceof File && file.size > 0, {
          message: 'File is required',
        })
}))

const { handleSubmit } = useForm({
  validationSchema: formSchema,
})

const { isPending, mutate } = useMutation({
  mutationFn: (formData: FormData) => axios.post(`/api/v1/tasks/bulk`, formData),
  onSuccess: () => {
    emit('close')

    toast({
        title: 'Bulk Offers',
        description: 'Successfully requested a bulk offer report.',
      });
  }
})

const onSubmit = handleSubmit((values) => {
  const formData = new FormData();
  formData.append('file', values.file);

  mutate(formData)
})

</script>

<template>
  <DialogContent @pointerDownOutside="$emit('close')" @close="$emit('close')">
    <DialogHeader>
      <DialogTitle>Bulk Offers</DialogTitle>
      <DialogDescription>
        Request a bulk offer report.
      </DialogDescription>
    </DialogHeader>

    <form class="space-y-4" @submit="onSubmit" id="form">
      <FormField name="file" v-slot="{ componentField }">
        <FormItem>
          <FormLabel>Bulk CSV</FormLabel>

          <FormControl>
            <Input type="file" accept=".csv,.xlsx" v-bind="componentField" />
          </FormControl>
        </FormItem>
      </FormField>
    </form>

    <DialogFooter>
      <Button variant="secondary" @click="$emit('close')">Cancel</Button>
      <Button type="submit" form="form" :disabled="isPending">
        <Loader2 class="w-4 h-4 animate-spin" v-if="isPending" />
        <slot v-if="!isPending">Confirm</slot>
      </Button>
    </DialogFooter>
  </DialogContent>
</template>

<style scoped>
</style>