<script setup lang="ts">
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from "@/components/ui/button";
import { defineProps, defineEmits } from "vue";
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { useMutation } from '@tanstack/vue-query'
import axios from 'axios'
import { useToast } from '@/components/ui/toast/use-toast'
import {Loader2} from "lucide-vue-next";


defineProps(["open"]);

const emit = defineEmits(["close"]);
const { toast } = useToast()
const formSchema = toTypedSchema(z.object({
  vendor: z.string(),
}))


const { handleSubmit } = useForm({
  validationSchema: formSchema,
})

const { isPending, isError, error, isSuccess, mutate } = useMutation({
  mutationFn: (data: { vendor: string }) => axios.post(`/api/v1/tasks/reprice`, data),
  onSuccess: () => {
    emit('close')
    toast({
        title: 'Repricing Task',
        description: 'Successfully triggered your repricing task.',
      });
  }
})

const onSubmit = handleSubmit((values) => {
  mutate(values)

  console.log(isPending.value, isError.value, error.value, isSuccess.value)
})

</script>

<template>
  <DialogContent @pointerDownOutside="$emit('close')" @close="$emit('close')">
    <DialogHeader>
      <DialogTitle>Start Repricing</DialogTitle>
      <DialogDescription>
        Trigger a manual repricing task for a specific vendor.
      </DialogDescription>
    </DialogHeader>

    <form class="space-y-4" @submit="onSubmit" id="form">
      <FormField name="vendor" v-slot="{ componentField }">
        <FormItem>
          <FormLabel>Vendor</FormLabel>

          <Select v-bind="componentField">
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Select a vendor to start a manual repricing task for." />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="flight_club">
                  Flight Club
                </SelectItem>
                <SelectItem value="stockx">
                  StockX
                </SelectItem>
                <SelectItem value="alias">
                  Alias
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </FormItem>
      </FormField>
    </form>

    <DialogFooter>
      <Button variant="secondary" @click="$emit('close')">Cancel</Button>
      <Button type="submit" form="form" :disabled="isPending">
        <Loader2 class="w-4 h-4 animate-spin" v-if="isPending" />
        <slot v-if="!isPending">Confirm</slot>
      </Button>
    </DialogFooter>
  </DialogContent>
</template>

<style scoped>
</style>