import { defineStore } from "pinia";

const useTitleStore = defineStore('titleData', {
    state: () => ({
        title: 'Orders',
        subtitle: {
            title: 'All',
            value: 'all'
        },
        url: '/orders/:tab'
    }),
})

export { useTitleStore }