<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter, SidebarGroup, SidebarGroupLabel, SidebarHeader, SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton,
  SidebarMenuSubItem, SidebarRail
} from "@/components/ui/sidebar";
import {Collapsible, CollapsibleContent, CollapsibleTrigger} from "@/components/ui/collapsible";
import {
  BadgeDollarSign,
  Box,
  Boxes,
  ChevronRight,
  ChevronsUpDown,
  LogOut,
  MoreHorizontal,
  PieChart,
  Plus,
  SettingsIcon,
  TableProperties,
  User
} from "lucide-vue-next";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { useTitleStore } from "@/stores/title";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";
import {onBeforeMount, ref} from "vue";
import Repricing from "@/components/modals/Repricing.vue";
import {Dialog} from "@/components/ui/dialog";
import Bulk from "@/components/modals/Bulk.vue";

const titleStore = useTitleStore();
const userStore = useUserStore();
const router = useRouter();

function logout() {
  localStorage.removeItem('jwt');
  router.push({name: 'login'});
}


const isRepricingDialogOpen = ref(false); // Dialog state

// Functions to open and close the dialog
function openRepricingDialog() {
  isRepricingDialogOpen.value = true;
}

function closeRepricingDialog() {
  isRepricingDialogOpen.value = false;
}

const isBulkDialogOpen = ref(false); // Dialog state

// Functions to open and close the dialog
function openBulkDialog() {
  isBulkDialogOpen.value = true;
}

function closeBulkDialog() {
  isBulkDialogOpen.value = false;
}

const data = {
  navMain: [
    {
      title: 'Orders',
      url: '/orders/:tab',
      icon: Boxes,
      isActive: false,
      items: [
        {
          title: 'All',
          value: 'all',
        },
        {
          title: 'Incoming',
          value: 'incoming',
        },
        {
          title: 'Processing',
          value: 'processing',
        },
        {
          title: 'Closed Out',
          value: 'completed',
        },
      ],
    },
    {
      title: 'Inventory',
      url: '/inventory/:tab',
      icon: Box,
      items: [
        {
          title: 'All',
          value: 'all',
        },
        {
          title: 'Active',
          value: 'active',
        },
        {
          title: 'Processing',
          value: 'processing',
        },
        {
          title: 'Sold',
          value: 'sold',
        },
        {
          title: 'Returns',
          value: 'returns',
        },
        {
          title: 'Miscellaneous',
          value: 'miscellaneous',
        },
      ],
    },
    {
      title: 'Sales',
      url: '/sales/:tab',
      icon: PieChart,
      items: [
        {
          title: 'Itemized',
          value: 'itemized',
        },
        {
          title: 'Monthly',
          value: 'monthly',
        },
        {
          title: 'Miscellaneous',
          value: 'miscellaneous',
        },
      ],
    },
    {
      title: 'Users',
      url: '/users',
      icon: User,
      items: [],
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: SettingsIcon,
      items: [],
    }
  ],
  tasks: [
    {
      title: 'Bulk',
      url: '/bulk',
      icon: TableProperties,
      open: openBulkDialog,
    },
    {
      title: 'Repricing',
      url: '/repricing',
      icon: BadgeDollarSign,
      open: openRepricingDialog,
    },
  ],
}

onBeforeMount(() => {
  if (!userStore._loaded) {
    userStore.fetchUser();
  }
})

</script>

<template>
<Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton tooltip="Soled">
              <img src="../../assets/favicon.ico" alt="Soled" />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Dashboard</SidebarGroupLabel>
          <SidebarMenu>
            <Collapsible
              v-for="item in data.navMain"
              :key="item.title"
              as-child
              :default-open="item.isActive"
              class="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger as-child>
                  <SidebarMenuButton :tooltip="item.title" v-if="item.items.length <= 0" @click="titleStore.title = item.title; titleStore.subtitle = {title: '', value: ''}; titleStore.url = item.url">
                      <component :is="item.icon" />
                      <router-link :to="item.url" >
                        {{ item.title }}
                      </router-link>
                  </SidebarMenuButton>

                  <SidebarMenuButton :tooltip="item.title" v-else>
                      <component :is="item.icon" />
                      <span>{{ item.title }}</span>
                      <ChevronRight class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"/>
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent v-if="item.items.length > 0">
                  <SidebarMenuSub>
                    <SidebarMenuSubItem
                      v-for="subItem in item.items"
                      :key="subItem.title"
                    >
                      <SidebarMenuSubButton @click="titleStore.title = item.title; titleStore.subtitle = subItem; titleStore.url = item.url">
                        <router-link
                            :to="{
                              name: item.url,
                              params: { tab: subItem.value },
                            }"
                        >
                          {{ subItem.title }}
                        </router-link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          </SidebarMenu>
        </SidebarGroup>
        <SidebarGroup class="group-data-[collapsible=icon]:hidden">
          <SidebarGroupLabel>Tasks</SidebarGroupLabel>
          <SidebarMenu>
            <SidebarMenuItem
              v-for="item in data.tasks"
              :key="item.title"
            >
              <SidebarMenuButton as-child @click="titleStore.title = item.title; titleStore.subtitle = {title: '', value: ''}; titleStore.url = item.url">
                <div>
                  <component :is="item.icon" />
                  <router-link :to="item.url">
                    {{ item.title }}
                  </router-link>
                </div>
              </SidebarMenuButton>
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <SidebarMenuAction show-on-hover>
                    <MoreHorizontal />
                    <span class="sr-only">More</span>
                  </SidebarMenuAction>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="w-48 rounded-lg" side="bottom" align="end">
                  <DropdownMenuItem @click="item.open">
                    <Plus class="text-muted-foreground" />
                    <span>Start New</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <SidebarMenuButton
                  size="lg"
                  class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <Avatar class="h-8 w-8 rounded-lg">
                    <AvatarFallback class="rounded-lg">
                      {{ userStore.fullName.charAt(0).toUpperCase() }}
                    </AvatarFallback>
                  </Avatar>
                  <div class="grid flex-1 text-left text-sm leading-tight">
                    <span class="truncate font-semibold">{{ userStore.fullName }}</span>
                    <span class="truncate text-xs">{{ userStore.email }}</span>
                  </div>
                  <ChevronsUpDown class="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side="bottom" align="end" :side-offset="4">
                <DropdownMenuLabel class="p-0 font-normal">
                  <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar class="h-8 w-8 rounded-lg">
                      <AvatarFallback class="rounded-lg">
                        {{ userStore.fullName.charAt(0).toUpperCase() }}
                      </AvatarFallback>
                    </Avatar>
                    <div class="grid flex-1 text-left text-sm leading-tight">
                      <span class="truncate font-semibold">{{ userStore.fullName }}</span>
                      <span class="truncate text-xs">{{ userStore.email }}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem @click="logout">
                  <LogOut />
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>

  <Dialog :open="isRepricingDialogOpen" @close="closeRepricingDialog">
    <Repricing @close="closeRepricingDialog" />
  </Dialog>

  <Dialog :open="isBulkDialogOpen" @close="closeBulkDialog">
    <Bulk @close="closeBulkDialog" />
  </Dialog>
</template>

<style scoped>

</style>