<script setup lang=ts>
import { useRoute } from 'vue-router'
import Toaster from '@/components/ui/toast/Toaster.vue'

import {
  InletHeader,
  Sidebar,
} from '@/components/navigation'

import {
  SidebarInset,
  SidebarProvider,
} from '@/components/ui/sidebar'

const route = useRoute()
</script>

<template>
  <Toaster />
  <SidebarProvider v-if="route.name !== 'login'">
    <Sidebar />

    <SidebarInset>
      <InletHeader />

      <div class="flex items-center gap-2 px-4">
        <RouterView />
      </div>
    </SidebarInset>
  </SidebarProvider>

  <RouterView v-else />

</template>