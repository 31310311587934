import { defineStore } from "pinia";
import axios from 'axios'

const useUserStore = defineStore('userData', {
    state: () => ({
        fullName: "",
        email: null,
        isAdmin: false,
        uuid: null,
        _loaded: false
    }),
    actions: {
        async fetchUser() {
            try {
                const response = await axios.get('/api/v1/auth/me')
                this.fullName = response.data.full_name
                this.email = response.data.email
                this.isAdmin = response.data.is_admin
                this.uuid = response.data.uuid
            } catch (error) {
                console.error(error)
            }
        }
    }
})

export { useUserStore }