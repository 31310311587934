<script setup lang="ts">
import { SidebarTrigger } from "@/components/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { useTitleStore } from "@/stores/title.ts";

const store = useTitleStore();
</script>

<template>
  <header class="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
    <div class="flex items-center gap-2 px-4">
      <SidebarTrigger class="-ml-1" />
      <Separator orientation="vertical" class="mr-2 h-4" />
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem class="hidden md:block">
            <component :is="store.subtitle.value !== '' ? BreadcrumbLink : BreadcrumbPage">
              {{ store.title }}
            </component>
          </BreadcrumbItem>

            <BreadcrumbSeparator class="hidden md:block" v-if="store.subtitle.value !== ''" />
            <BreadcrumbItem>
              <BreadcrumbPage v-if="store.subtitle.value !== ''">
                <router-link :to="{
                  name: store.url,
                  params: { tab: store.subtitle.value },
                }">
                  {{ store.subtitle.title }}
                </router-link>
              </BreadcrumbPage>
            </BreadcrumbItem>

        </BreadcrumbList>
      </Breadcrumb>
    </div>
  </header>
</template>

<style scoped>

</style>