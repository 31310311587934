import { createApp } from 'vue'
import './assets/index.css'
import App from './App.vue'
import router from './router.ts'
import { createPinia } from 'pinia'
import axios from 'axios'
import { VueQueryPlugin } from '@tanstack/vue-query'
import * as Sentry from "@sentry/vue";

const pinia = createPinia()

// axios.defaults.withCredentials = true
axios.defaults.baseURL = import.meta.env.VITE_BASE_API

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwt');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://7ef7e878941906497eb0e1b0859f3e96@o4507744218447872.ingest.us.sentry.io/4508695404937216",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/soled\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
    .use(pinia)
    .use(VueQueryPlugin)
    .mount('#app')
